<template>
    <div class="modal fade show" :class="{small, short, auto, blur}" :id="id" v-if="active || open"
         @mousedown.self="close">
        <div class="modal-content">
            <div class="close" @click.prevent="close"></div>

            <div class="modal-body">

                <div class="modal-header">
                    <slot name="title" :data="dataModal">
                        <h5 class="modal-title" v-html="title"></h5>
                        <div class="modal-description" v-if="description" v-html="description"></div>
                    </slot>
                </div>

                <slot :data="dataModal"></slot>
            </div>

            <div class="modal-footer">
                <slot name="footer" :close="close"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import modal from "@/mixins/modal/modal";

export default {
    name: "modal",
    mixins: [modal],
    model: {
        event: 'close',
        prop: 'open',
    },
    props: {
        open: {},
        small: Boolean,
        short: Boolean,
        auto: Boolean,
        blur: Boolean,
    },
    methods: {
        close(...args) {
            this.closeModal(...args);
            this.$emit('close', false);
        },
    },
}
</script>

<style lang="scss">
    .modal {
        position: fixed;
        z-index: 999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        align-items: flex-start;
        justify-content: center;
        background: rgba($blackBlue, 0.8);
        max-height: 100vh;
        overflow: auto;
        @include safeArea;

        @include mobileUp {
            align-items: center;
        }

        &.show {
            display: grid;
        }

        &.auto {
            .modal-content {
                width: auto;
            }
        }

        &.small {
            .modal-content {
                width: 376px;
            }
        }

        &.short {

            .modal-content {
                width: 466px;
                margin: auto;

                .modal-body {
                    .modal-header {
                        &:only-child {
                            margin-bottom: 0;
                        }

                        .modal-title {
                            padding-right: 20px;
                        }

                        .modal-description {
                            margin-top: 13px;

                            font-size: 16px;
                            line-height: 24px;
                            color: $blackBlue;
                        }
                    }
                }

                .close {
                    background-color: transparent;

                    @include mobileUp {
                        top: 16px;
                        right: 16px;
                    }
                }
            }
        }

        &.blur {
            backdrop-filter: blur(8px);
        }

        .modal-content {
            background: $white;
            width: 564px;
            max-width: 100%;
            max-width: calc(100vw - 16px);
            position: relative;
            border-radius: 12px;
            margin: 8px;

            @include mobileUp {
                margin: 32px;
                max-width: calc(100vw - 64px);
            }

            .close {
                position: absolute;
                top: 8px;
                right: 8px;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: $grayBackground url("~@/assets/img/icons/close.svg") no-repeat center center;
                cursor: pointer;
                transition: all 0.25s;

                &:hover {
                    background-color: $grayBorder;
                }
            }
        }

        .modal-header {
            margin-top: 8px;
            margin-bottom: 16px;

            @include mobileUp {
                margin-bottom: 20px;
            }

            .modal-title {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                color: $blackBlue;

                @include mobileUp {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            .modal-description {
                font-size: 14px;
                line-height: 24px;
                color: $grayText;
                margin-top: 12px;
            }
        }

        .modal-body {
            padding: 14px 16px;

            @include mobileUp {
                padding: 22px 24px;
            }
        }

        .modal-footer {
            display: flex;
            border-top: 1px solid rgba($blackBlue, 0.08);
            padding: 16px;

            &:empty {
                display: none;
            }

            button {
                width: 100%;
            }

            @include mobileUp {
                padding: 20px 24px;
            }
        }
    }
</style>
