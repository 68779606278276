<template>
    <img v-lazy-load class="logo" src="@/assets/img/header/logo-v2.svg" alt="logo" loading="lazy">
</template>

<script>
export default {
    name: "logo"
}
</script>

<style lang="scss">
.logo {
    @include mobile {
        width: 112px;
    }
}
</style>
