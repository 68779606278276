<template>
    <router-link :to="route" @click.native="handleClick" class="no-decoration">
        <slot/>
    </router-link>
</template>

<script>

import {getRouteToCars} from "@/utils/router";
import {mapMutations} from "vuex";

export default {
    name: "routerToCars",
    props: {
        city: String,
        filters: {
            type: Object,
            default: () => ({}),
        },
        scroll: {
            type: Boolean,
            default: true,
        },
        saveFilters: Boolean,
    },
    computed: {
        route() {
            let { city, ...filters } = this.filters;
            if (this.saveFilters) {
                filters = {
                    sort: this.$store.state.cars.sort,
                    ...this.$store.state.cars.filters,
                    ...filters,
                };
            }
            return getRouteToCars(filters, city || this.city);
        },
        isCurrentRoute() {
            const fullPath = this.$router.match(this.route).fullPath;
            return this.$route.fullPath === fullPath;
        },
    },
    methods: {
        handleClick() {
            this.scrollToTop();
            if (!this.saveFilters) {
                let { city, ...filters } = this.filters;
                this.setFiltersObj(filters);
            }
        },
        scrollToTop() {
            if (this.scroll && process.client) {
                this.$scrollTo('body');
                window.scrollTo(0, 0);
            }
        },
        ...mapMutations('cars', ['setFiltersObj']),
    },
}
</script>

<style lang="scss">
a.no-decoration {
    text-decoration: none;
}
</style>
