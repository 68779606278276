const styleThemeNames = ['audi'];

export default {
    props: {
        styleTheme: {
            type: String,
            validator(styleTheme) {
                return !styleTheme || styleThemeNames.includes(styleTheme);
            },
        },
    },
    computed: {
        styleThemeClass() {
            if (this.styleTheme) {
                return `style-theme-${this.styleTheme}`;
            }
        },
    },
}
