export const foldingHeightTransition = {
    methods:{
        afterEnter(element) {
            element.style.height = `auto`;
        },
        enter(element) {
            element.style.visibility = `hidden`;
            element.style.height = `auto`;

            const { height } = getComputedStyle(element);

            element.style.visibility = null;
            element.style.height = 0;

            getComputedStyle(element).height;
            requestAnimationFrame(() => {
                element.style.height = height;
            });
        },
        leave(element) {
            const { height } = getComputedStyle(element);
            element.style.height = height;

            getComputedStyle(element).height;
            requestAnimationFrame(() => {
                element.style.height = 0;
            });
        }
    }
}
