<template>
    <div class="rating-line" :class="styleThemeClass">
        <div
            class="star"
            :style="size"
            v-for="(_, index) in 5"
            :key="index"
            v-html="getIcon(index)"
        />
    </div>
</template>

<script>
import styleTheme from "@/mixins/style/styleTheme";
import EmptyStartIcon from "@/assets/img/icons/rating/rating-star-empty.svg?raw";
import HalfStartIcon from "@/assets/img/icons/rating/rating-star-half.svg?raw";
import ActiveStartIcon from "@/assets/img/icons/rating/rating-star.svg?raw";

export default {
    name: "ratingLine",
    mixins: [styleTheme],
    props: {
        height: {
            type: Number,
            default: 16,
        },
        rating: [Number, String],
        max: {
            type: Number,
            default: 5,
        },
    },
    computed: {
        startRating() {
            return this.rating / this.max * 5;
        },
        size() {
            const size = `${this.height}px`;
            return {
                height: size,
                width: size,
            }
        },
    },
    methods: {
        getIcon(index) {
            if (this.startRating - index >= 1) {
                return ActiveStartIcon;
            }
            if (1 > this.startRating - index && this.startRating - index >= 0.25) {
                return HalfStartIcon
            }
            return EmptyStartIcon;
        }
    },
}
</script>

<style lang="scss">
.rating-line {
    display: inline-grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 3px;

    &.style-theme-audi {
        .star path {
            fill: $audi_card;

            &.active-path-fill {
                fill: $audi_accent;
            }
        }
    }

    .star {
        display: flex;
        height: 100%;

        svg {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
