<template>
    <div :class="['profile-avatar', size]" :title="userName">
        <span class="user-name-first-character">
            {{ userNameFirstCharacter }}
        </span>
        <div class="img" v-lazy-background :lazy-background="imageBackground"/>
        <div v-if="online" class="user-online-mark"/>
    </div>
</template>

<script>
import {getUrlFromFile} from "@/utils/files";

export default {
    name: "profileAvatar",
    props: {
        image: {},
        url: String,
        size: {
            type: String,
            default: 'normal',
        },
        userName: String,
        online: Boolean,
    },
    computed: {
        imageBackground() {
            if (this.haveImage) {
                return getUrlFromFile(this.image) || this.url;
            }
        },
        haveImage() {
            return this.image || this.url;
        },
        userNameFirstCharacter() {
            return this.userName ? this.userName.slice(0, 1) : '';
        },
    },
}
</script>

<style lang="scss">
.profile-avatar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 50%;
    background-color: $primary;
    text-transform: capitalize;

    font-size: 18px;
    font-weight: 700;
    color: $white;

    @include size(56px);

    @include mobile {
        font-size: 16px;
        font-weight: 600;

        @include size(28px);
    }

    &.sm {
        font-size: 14px;
        line-height: 14px;
        @include size(32px);

        @include mobile {
            @include size(28px);
        }
    }


    &.md {
        @include size(64px);

        @include mobile {
            @include size(48px);
        }
    }

    &.md-fixed {
        @include size(60px);
    }

    &.large {
        font-size: 42px;
        @include size(120px);

        @include mobile {
            @include size(180px);
        }

        .user-online-mark {
            top: 0;
            right: -3px;

            width: 24px;
            height: 24px;

            border-width: 3px;

            @include mobile {
                top: 3px;
                right: -4px;

                width: 20px;
                height: 20px;

                border-width: 2px;
            }
        }
    }

    .img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    }

    .user-online-mark {
        position: absolute;

        top: 0;
        right: -4px;

        width: 12px;
        height: 12px;

        border-radius: 50%;
        background: #10C865;
        border: 2px solid $white;
    }
}
</style>
