const modalOpenClassName = 'modal-open';
const bodyScrollWidthPropertyName = '--body-scroll-width';

export default (nameState = 'active') => ({
    data: () => ({
        isNestedModal: false,
    }),
    mounted() {
        if (process.client) {
            this.isNestedModal = document.body.classList.contains(modalOpenClassName);
        }
    },
    methods: {
        hideBodyScroll(active) {
            if (process.client) {
                if (!this.isNestedModal) {
                    if (active) {
                        const bodyScrollWidth = window.innerWidth - document.body.offsetWidth;
                        if (bodyScrollWidth) {
                            document.body.style.setProperty(bodyScrollWidthPropertyName, `${bodyScrollWidth}px`);
                        }
                        this.$nextTick(() => document.body.classList.add('modal-open', this.id ?? 'modal-name'));
                    } else {
                        document.body.style.removeProperty(bodyScrollWidthPropertyName);
                        document.body.classList.remove('modal-open', this.id ?? 'modal-name');
                    }
                }
            }
        }
    },
    watch: {
        [nameState](val) {
            if (val) this.$emit('open');
            else this.$emit('close');
            this.hideBodyScroll(val);
        },
    },
    created() {
        this.hideBodyScroll(this[nameState]);
        if (this[nameState] && process.client) {
            this.$emit('open');
        }
    },
    beforeDestroy() {
        this.hideBodyScroll(false);
    },
});
