import { mapMutations, mapState } from 'vuex';
import blockScroll from "@/mixins/modal/blockScroll";
import { replaceQueryObj } from "@/utils/router";

export default {
    props: {
        id: {},
        title: {},
        description: {},
        query: {},
        open: Boolean,
        noQuery: Boolean,
    },
    mixins: [blockScroll('active')],
    computed: {
        active() {
            return this.id && this.id === this.activeModal || this.open;
        },
        ...mapState('modal', ['activeModal', 'dataModal']),
    },
    methods: {
        ...mapMutations('modal', ['closeModal']),
    },
    watch: {
        active(v){
            const modal = v ? this.id : this.activeModal;
            if (!this.noQuery && (modal || this.$route.query.modal)) {
                replaceQueryObj(this, {
                    modal: modal || undefined,
                    [this.query]: modal ? this.$route.query[this.query] : undefined,
                });
            }
        }
    }
}
